import React from 'react';

const ManagedRegeneration = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 15" fill="none">
      <path
        d="M9.97335 5.50295H11.3606V4.1792H9.97335V5.50295ZM15.027 5.50295H16.4162V4.1792H15.027V5.50295ZM4.91966 5.50295H6.30695V4.1792H4.91966V5.50295ZM0.166992 5.50295H1.25326V4.1792H0.166992V5.50295ZM20.0807 4.1792V5.50295H21.167V4.1792H20.0807Z"
        fill="white"
      />
      <path
        d="M9.97335 12.4739H11.3606V11.1501H9.97335V12.4739ZM15.027 12.4739H16.4162V11.1501H15.027V12.4739ZM4.91966 12.4739H6.30695V11.1501H4.91966V12.4739ZM0.166992 12.4739H1.25326V11.1501H0.166992V12.4739ZM20.0807 11.1501V12.4739H21.167V11.1501H20.0807Z"
        fill="white"
      />
      <path
        d="M18.2519 0.61499L16.5879 2.27675V14.615H19.9047V2.27675L18.2519 0.61499ZM18.2519 12.0524C18.1079 12.0524 17.9901 11.9533 17.9901 11.8323C17.9901 11.7112 18.1079 11.6122 18.2519 11.6122C18.3959 11.6122 18.5136 11.7112 18.5136 11.8323C18.5136 11.9533 18.3959 12.0524 18.2519 12.0524ZM18.2519 5.08147C18.1079 5.08147 17.9901 4.98242 17.9901 4.86137C17.9901 4.74031 18.1079 4.64127 18.2519 4.64127C18.3959 4.64127 18.5136 4.74031 18.5136 4.86137C18.5136 4.98242 18.3959 5.08147 18.2519 5.08147Z"
        fill="white"
      />
      <path
        d="M13.1982 0.61499L11.5342 2.27675V14.615H14.851V2.27675L13.1982 0.61499ZM13.1982 12.0524C13.0542 12.0524 12.9364 11.9533 12.9364 11.8323C12.9364 11.7112 13.0542 11.6122 13.1982 11.6122C13.3421 11.6122 13.4599 11.7112 13.4599 11.8323C13.4599 11.9533 13.3421 12.0524 13.1982 12.0524ZM13.1982 5.08147C13.0542 5.08147 12.9364 4.98242 12.9364 4.86137C12.9364 4.74031 13.0542 4.64127 13.1982 4.64127C13.3421 4.64127 13.4599 4.74031 13.4599 4.86137C13.4599 4.98242 13.3421 5.08147 13.1982 5.08147Z"
        fill="white"
      />
      <path
        d="M8.14446 0.61499L6.48047 2.27675V14.615H9.79724V2.27675L8.14446 0.61499ZM8.14446 12.0524C8.0005 12.0524 7.88271 11.9533 7.88271 11.8323C7.88271 11.7112 8.0005 11.6122 8.14446 11.6122C8.28843 11.6122 8.40622 11.7112 8.40622 11.8323C8.40622 11.9533 8.28843 12.0524 8.14446 12.0524ZM8.14446 5.08147C8.0005 5.08147 7.88271 4.98242 7.88271 4.86137C7.88271 4.74031 8.0005 4.64127 8.14446 4.64127C8.28843 4.64127 8.40622 4.74031 8.40622 4.86137C8.40622 4.98242 8.28843 5.08147 8.14446 5.08147Z"
        fill="white"
      />
      <path
        d="M3.09075 0.61499L1.42676 2.27675V14.615H4.7454V2.27675L3.09075 0.61499ZM3.09075 12.0524C2.94679 12.0524 2.829 11.9533 2.829 11.8323C2.829 11.7112 2.94679 11.6122 3.09075 11.6122C3.23472 11.6122 3.35251 11.7112 3.35251 11.8323C3.35251 11.9533 3.23472 12.0524 3.09075 12.0524ZM3.09075 5.08147C2.94679 5.08147 2.829 4.98242 2.829 4.86137C2.829 4.74031 2.94679 4.64127 3.09075 4.64127C3.23472 4.64127 3.35251 4.74031 3.35251 4.86137C3.35251 4.98242 3.23472 5.08147 3.09075 5.08147Z"
        fill="white"
      />
    </svg>
  );
};

export default ManagedRegeneration;
