const DefaultProfileImageIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#DFF4E8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4632 9.93092C16.4632 9.93092 15.6142 9.97331 15.423 10.8185C15.423 10.8185 15.4202 8.32812 13.5176 6.94321C13.5176 6.94321 12.9615 7.37839 12.9727 8.28421C12.9727 8.28421 11.3727 6.4935 7.93948 5.60212C6.98604 9.05149 7.74561 11.3021 7.74561 11.3021C6.96679 10.8394 6.31185 11.1034 6.31185 11.1034C6.56035 13.4433 8.71523 14.6912 8.71523 14.6912C7.88769 14.4342 7.42645 15.1483 7.42645 15.1483C9.48192 17.8293 12.2914 18.2031 14.3401 17.1436C14.4749 17.0738 14.6066 16.9978 14.7344 16.9159C16.6763 15.6714 17.7575 13.0519 16.4632 9.93092ZM14.3401 17.1436L13.4524 15.6061L10.8222 15.3421L10.8748 14.8956L13.1756 15.1267L11.9034 12.9232L9.27324 12.6591L9.32553 12.2122L11.6264 12.4434L9.97035 9.57501L10.3647 9.34735L12.0207 12.2157L12.971 10.1075L13.3841 10.2857L12.2977 12.6955L13.57 14.8991L14.5202 12.7909L14.9331 12.9686L13.8467 15.3784L16.1366 19.3447L15.7423 19.5724L14.3401 17.1436Z"
        fill="#219653"
      />
    </svg>
  );
};

export default DefaultProfileImageIcon;
