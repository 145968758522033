import themeProperties from '../../../../src/theme/themeProperties';

interface Props {
  color?: string;
}

const VTOChallengeIcon = ({
  color = themeProperties.light.primaryFontColor,
}: Props) => {
  return (
    <svg
      viewBox="0 0 512 565"
      width="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.242 306.812C179.242 304.377 179.242 304.377 179.242 304.377C138.064 367.701 138.064 367.701 138.064 367.701C31.4872 372.572 31.4872 372.572 31.4872 372.572C12.1103 375.007 0 392.056 0 409.105C2.42095 426.154 14.5333 438.333 33.9099 438.333H36.3326C157.442 431.025 157.442 431.025 157.442 431.025C167.132 426.154 176.819 423.718 181.665 413.976C217.997 360.394 217.997 360.394 217.997 360.394C203.464 348.217 184.086 331.168 179.242 306.812Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M497.718 253.639C419.167 239.298 419.167 239.298 419.167 239.298C419.167 239.298 395.363 179.556 392.981 174.777C378.699 141.32 354.895 119.81 304.908 107.862C223.975 83.9674 223.975 83.9674 223.975 83.9674C214.456 83.9674 209.693 83.9674 202.552 88.7464C114.479 150.878 114.479 150.878 114.479 150.878C107.337 158.046 104.959 167.608 109.719 177.166C114.479 186.724 126.382 189.114 135.901 186.724C221.597 141.32 221.597 141.32 221.597 141.32C273.962 153.267 273.962 153.267 273.962 153.267C259.68 177.166 259.68 177.166 259.68 177.166C204.933 256.028 204.933 256.028 204.933 256.028C195.411 275.144 190.651 289.486 195.411 303.823C197.793 318.16 212.074 332.501 223.975 342.059L312.049 394.632C262.061 523.678 262.061 523.678 262.061 523.678C254.921 538.019 264.443 554.745 281.103 564.304C285.866 564.304 288.244 564.304 293.007 564.304C304.908 564.304 316.812 557.135 323.953 542.798C378.699 394.632 378.699 394.632 378.699 394.632C383.459 380.295 378.699 361.175 366.799 356.396C285.866 306.212 285.866 306.212 285.866 306.212C350.135 213.013 350.135 213.013 350.135 213.013C381.081 275.144 381.081 275.144 381.081 275.144C383.459 279.923 392.981 282.313 397.741 282.313C492.955 289.486 492.955 289.486 492.955 289.486C500.096 291.875 509.619 282.313 512 275.144C512 265.586 507.237 253.639 497.718 253.639Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M398.829 113.09C403.492 113.09 408.155 113.09 410.488 113.09C440.802 106.022 459.455 75.3931 454.792 44.7642C447.796 18.8476 424.479 0 398.829 0C394.165 0 391.833 0 387.169 0C356.856 9.4233 335.87 37.6961 345.196 68.325C347.529 94.2416 373.178 113.09 398.829 113.09Z"
        fill={color}
      />
    </svg>
  );
};

export default VTOChallengeIcon;
